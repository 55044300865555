<template>
  <div>
    <b-modal
      no-close-on-backdrop
      v-model="show"
      id="modal-center"
      hide-footer
      hide-header
      centered
    >
      <b-card-text>
        <div class="header text-right">
          <b-button @click="closeServicePopUp" variant="flat-primary"
            ><feather-icon icon="XIcon"></feather-icon
          ></b-button>
        </div>
        <div class="pl-2 pr-2 pb-2 pt-0 edit-form">
          <h3 class="app-title lg">
            {{ action === "add-new" ? "Add Sector" : "Edit Sector" }}
          </h3>
          <validation-observer ref="consultantForm" #default="{ invalid }">
            <div class="form-fields mt-3">
              <b-row>
                <b-col cols="12" md="12"
                  ><b-form-group>
                    <validation-provider
                      #default="{ errors }"
                      name="Sector Name"
                      rules="required"
                    >
                      <label for="client-name">Sector Name</label>
                      <b-input-group>
                        <b-input-group-prepend is-text>
                          <feather-icon
                            icon="ShoppingBagIcon"
                            class="cursor-pointer"
                          />
                        </b-input-group-prepend>
                        <b-form-input
                          v-model="sector.name"
                          id="client-name"
                          placeholder="Enter sector name"
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group></b-col
                >
              </b-row>

              <b-button
                @click="processData"
                :disabled="isBusy"
                class="py-1 mt-2"
                block
                variant="primary"
              >
                <b-spinner v-if="isBusy" small class="mr-1" />
                {{
                  action === "add-new" ? "Create Sector" : "Save Changes"
                }}</b-button
              >
            </div>
          </validation-observer>
        </div>
      </b-card-text>
    </b-modal>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BModal,
  BCardText,
  BButton,
  BInputGroupPrepend,
  BFormInput,
  BInputGroup,
  BFormGroup,
  BFormSelect,
  BSpinner,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import axios from "axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    sector: {
      _id: "",
      name: "",
    },
    action: {
      type: String,
      required: true,
    },
  },
  components: {
    BRow,
    BCol,
    BModal,
    BCardText,
    BButton,
    BInputGroupPrepend,
    BFormInput,
    BInputGroup,
    BFormGroup,
    BFormSelect,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      required,
      email,
      isBusy: false,
    };
  },
  methods: {
    showToast(title, text, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: variant == "success" ? "CheckCircleIcon" : "AlertCircleIcon",
          text,
          variant,
        },
      });
    },
    closeServicePopUp() {
      this.$emit("closeServicePopUp", true);
    },
    processData() {
      this.$refs.consultantForm.validate().then((success) => {
        if (success) {
          if (this.action === "edit") {
            this.updateService(this.sector);
          } else if (this.action === "add-new") {
            let sector = this.sector;
            delete sector.id;
            this.addNew(sector);
          }
        }
      });
    },
    updateService(sector) {
      this.$set(this, "isBusy", true);
      const { _id, name } = sector;
      const token = this.$getUserToken();
      axios
        .put(
          `${process.env.VUE_APP_SERVER_URL}/sector`,
          {
            _id,
            name,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          this.$set(this, "isBusy", false);
          this.$emit("updated", true);
          this.showToast(
            "Updated",
            "A service has been updated successfully!",
            "success"
          );
        })
        .catch((error) => {
          console.log(error.response);
          this.$set(this, "isBusy", false);
          this.showToast(
            "Error",
            "Error while updating the service! Please try again later.",
            "danger"
          );
        });
    },
    addNew(sector) {
      this.$set(this, "isBusy", true);
      let token = this.$getUserToken();

      axios
        .post(`${process.env.VUE_APP_SERVER_URL}/sector`, sector, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          this.$set(this, "isBusy", false);
          this.$emit("added", true);
          this.showToast(
            "Created",
            "A service has been created successfully!",
            "success"
          );
        })
        .catch((error) => {
          console.log(error.response);
          this.$set(this, "isBusy", false);
          this.showToast(
            "Error",
            "Error while creating the service! Please try again later.",
            "danger"
          );
        });
    },
  },
};
</script>
